export const VisibilityIcon = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3143_16150)">
<path d="M10.1667 5.66667C13.325 5.66667 16.1417 7.44167 17.5167 10.25C16.1417 13.0583 13.325 14.8333 10.1667 14.8333C7.00833 14.8333 4.19167 13.0583 2.81667 10.25C4.19167 7.44167 7.00833 5.66667 10.1667 5.66667ZM10.1667 4C6 4 2.44167 6.59167 1 10.25C2.44167 13.9083 6 16.5 10.1667 16.5C14.3333 16.5 17.8917 13.9083 19.3333 10.25C17.8917 6.59167 14.3333 4 10.1667 4ZM10.1667 8.16667C11.3167 8.16667 12.25 9.1 12.25 10.25C12.25 11.4 11.3167 12.3333 10.1667 12.3333C9.01667 12.3333 8.08333 11.4 8.08333 10.25C8.08333 9.1 9.01667 8.16667 10.1667 8.16667ZM10.1667 6.5C8.1 6.5 6.41667 8.18333 6.41667 10.25C6.41667 12.3167 8.1 14 10.1667 14C12.2333 14 13.9167 12.3167 13.9167 10.25C13.9167 8.18333 12.2333 6.5 10.1667 6.5Z" fill="#455560"/>
</g>
<defs>
<clipPath id="clip0_3143_16150">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
);

export const DeleteIcon = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3143_16155)">
<path d="M13.1667 8V16.3333H6.5V8H13.1667ZM11.9167 3H7.75L6.91667 3.83333H4V5.5H15.6667V3.83333H12.75L11.9167 3ZM14.8333 6.33333H4.83333V16.3333C4.83333 17.25 5.58333 18 6.5 18H13.1667C14.0833 18 14.8333 17.25 14.8333 16.3333V6.33333Z" fill="#455560"/>
</g>
<defs>
<clipPath id="clip0_3143_16155">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
);

export const DeleteIconRed = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3143_16155)">
<path d="M13.1667 8V16.3333H6.5V8H13.1667ZM11.9167 3H7.75L6.91667 3.83333H4V5.5H15.6667V3.83333H12.75L11.9167 3ZM14.8333 6.33333H4.83333V16.3333C4.83333 17.25 5.58333 18 6.5 18H13.1667C14.0833 18 14.8333 17.25 14.8333 16.3333V6.33333Z" fill="#F53434"/>
</g>
<defs>
<clipPath id="clip0_3143_16155">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
);

export const PageIcon = () =>(
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.66659 12.3337H10.3333V14.0003H3.66659V12.3337ZM3.66659 9.00033H10.3333V10.667H3.66659V9.00033ZM8.66658 0.666992H1.99992C1.08325 0.666992 0.333252 1.41699 0.333252 2.33366V15.667C0.333252 16.5837 1.07492 17.3337 1.99159 17.3337H11.9999C12.9166 17.3337 13.6666 16.5837 13.6666 15.667V5.66699L8.66658 0.666992ZM11.9999 15.667H1.99992V2.33366H7.83325V6.50033H11.9999V15.667Z" fill="#475569"/>
</svg>

)

export const EditIcon = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3143_16160)">
<path d="M11.2167 8.01667L11.9833 8.78333L4.43333 16.3333H3.66667V15.5667L11.2167 8.01667ZM14.2167 3C14.0083 3 13.7917 3.08333 13.6333 3.24167L12.1083 4.76667L15.2333 7.89167L16.7583 6.36667C17.0833 6.04167 17.0833 5.51667 16.7583 5.19167L14.8083 3.24167C14.6417 3.075 14.4333 3 14.2167 3ZM11.2167 5.65833L2 14.875V18H5.125L14.3417 8.78333L11.2167 5.65833Z" fill="#455560"/>
</g>
<defs>
<clipPath id="clip0_3143_16160">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
);

export const BoxIcon = () => (<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.64258 6.7002L10.0009 10.9585L17.3092 6.72517" stroke="#455560" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.001 18.5085V10.9502" stroke="#455560" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.2755 2.56699L3.82551 5.04202C2.81717 5.60035 1.99219 7.00034 1.99219 8.15034V12.8587C1.99219 14.0087 2.81717 15.4087 3.82551 15.967L8.2755 18.442C9.2255 18.967 10.7838 18.967 11.7338 18.442L16.1839 15.967C17.1922 15.4087 18.0172 14.0087 18.0172 12.8587V8.15034C18.0172 7.00034 17.1922 5.60035 16.1839 5.04202L11.7338 2.56699C10.7755 2.03366 9.2255 2.03366 8.2755 2.56699Z" stroke="#455560" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.1661 11.5337V8.48369L6.25781 3.91699" stroke="#455560" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export const PaymentsIcon = () => (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3143_16178)">
<path d="M15.833 11.6668V5.00016C15.833 4.0835 15.083 3.3335 14.1663 3.3335H2.49967C1.58301 3.3335 0.833008 4.0835 0.833008 5.00016V11.6668C0.833008 12.5835 1.58301 13.3335 2.49967 13.3335H14.1663C15.083 13.3335 15.833 12.5835 15.833 11.6668ZM14.1663 11.6668H2.49967V5.00016H14.1663V11.6668ZM8.33301 5.8335C6.94967 5.8335 5.83301 6.95016 5.83301 8.3335C5.83301 9.71683 6.94967 10.8335 8.33301 10.8335C9.71634 10.8335 10.833 9.71683 10.833 8.3335C10.833 6.95016 9.71634 5.8335 8.33301 5.8335ZM19.1663 5.8335V15.0002C19.1663 15.9168 18.4163 16.6668 17.4997 16.6668H3.33301V15.0002H17.4997V5.8335H19.1663Z" fill="#455560"/>
</g>
<defs>
<clipPath id="clip0_3143_16178">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
);

export const CalenderIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_246_8241)">
<path d="M15.8333 3.33464H15V1.66797H13.3333V3.33464H6.66667V1.66797H5V3.33464H4.16667C3.24167 3.33464 2.50833 4.08464 2.50833 5.0013L2.5 16.668C2.5 17.5846 3.24167 18.3346 4.16667 18.3346H15.8333C16.75 18.3346 17.5 17.5846 17.5 16.668V5.0013C17.5 4.08464 16.75 3.33464 15.8333 3.33464ZM15.8333 16.668H4.16667V8.33464H15.8333V16.668ZM15.8333 6.66797H4.16667V5.0013H15.8333V6.66797ZM7.5 11.668H5.83333V10.0013H7.5V11.668ZM10.8333 11.668H9.16667V10.0013H10.8333V11.668ZM14.1667 11.668H12.5V10.0013H14.1667V11.668ZM7.5 15.0013H5.83333V13.3346H7.5V15.0013ZM10.8333 15.0013H9.16667V13.3346H10.8333V15.0013ZM14.1667 15.0013H12.5V13.3346H14.1667V15.0013Z" fill="#455560"/>
</g>
<defs>
<clipPath id="clip0_246_8241">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

)

export const BellIcon = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.01 20.01C7.01 21.11 7.9 22 9 22C10.1 22 10.99 21.11 10.99 20.01H7.01ZM9 5C11.76 5 14 7.24 14 10V17H4V10C4 7.24 6.24 5 9 5ZM9 0.5C8.17 0.5 7.5 1.17 7.5 2V3.17C4.36 3.85 2 6.65 2 10V16L0 18V19H18V18L16 16V10C16 6.65 13.64 3.85 10.5 3.17V2C10.5 1.17 9.83 0.5 9 0.5ZM8 7H10V11H8V7ZM8 13H10V15H8V13Z" fill="#455560"/>
</svg>

)