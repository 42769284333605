import * as React from "react";

const Logouticon = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 19H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h4M14 15l5-5-5-5M19 10H7"
      stroke="#004A7F"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Operation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 32 32"
    {...props}
  >
    <title>{"operations--record"}</title>
    <path d="M20 20h10v2H20zM20 24h10v2H20zM20 28h10v2H20zM16 20a3.912 3.912 0 0 1-4-4 3.912 3.912 0 0 1 4-4 3.912 3.912 0 0 1 4 4h2a6 6 0 1 0-6 6Z" />
    <path d="m29.305 11.044-2.36-4.088a1.998 1.998 0 0 0-2.374-.895l-2.434.824a11.042 11.042 0 0 0-1.312-.758l-.504-2.52A2 2 0 0 0 18.36 2h-4.72a2 2 0 0 0-1.961 1.608l-.504 2.518a10.967 10.967 0 0 0-1.327.754l-2.42-.819a1.998 1.998 0 0 0-2.372.895l-2.36 4.088a2 2 0 0 0 .411 2.502l1.931 1.697C5.021 15.495 5 15.745 5 16c0 .258.01.513.028.766l-1.92 1.688a2 2 0 0 0-.413 2.502l2.36 4.088a1.998 1.998 0 0 0 2.374.895l2.434-.824a10.974 10.974 0 0 0 1.312.759l.503 2.518A2 2 0 0 0 13.64 30H16v-2h-2.36l-.71-3.55a9.095 9.095 0 0 1-2.695-1.572l-3.447 1.166-2.36-4.088 2.725-2.395a8.926 8.926 0 0 1-.007-3.128l-2.718-2.39 2.36-4.087 3.427 1.16A9.03 9.03 0 0 1 12.93 7.55L13.64 4h4.72l.71 3.55a9.098 9.098 0 0 1 2.695 1.572l3.447-1.166 2.36 4.088-2.798 2.452L26.092 16l2.8-2.454a2 2 0 0 0 .413-2.502Z" />
    <path
      data-name="&lt;Transparent Rectangle&gt;"
      style={{
        fill: "none",
      }}
      d="M0 0h32v32H0z"
    />
  </svg>
)

const Hrms = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 1000 1000"
    {...props}
  >
    <path d="M665 457q34 0 62.5-16.5t45.5-45 17-62.5-17-63-45.5-45.5T665 208t-62.5 16.5-45 45.5-16.5 63 16.5 62.5 45 45T665 457zm-332 0q34 0 62.5-16.5t45-45T457 333t-16.5-63-45-45.5T333 208t-63 16.5-45.5 45.5-16.5 63 16.5 62.5 45.5 45 63 16.5zm0 84q-54 0-119 16-73 18-118 48-54 36-54 81v104h582V686q0-45-54-81-45-30-119-48-65-16-118-16zm332 0q-16 0-40 2 82 59 82 143v104h249V686q0-45-54-81-45-30-118-48-65-16-119-16z" />
  </svg>
)




export {Logouticon,Operation,Hrms };
