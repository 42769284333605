import { SIDE_NAV_DARK, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'
import axios from 'axios';

export const APP_NAME = 'Trust Cleanz';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const TC_INTERNAL_URL = 'tc-hrms-react.reddotapps.com.sg';
export const TC_STAGING_URL = 'tc-staging-hrms-react.reddotapps.com.sg';
export const TC_PROD_URL = 'tc-prod-hrms-react.reddotapps.com.sg';
export const TC_API_INTERNAL_URL = 'https://tc-api.reddotapps.com.sg';
export const TC_API_STAGING_URL = 'https://tc-staging-api.reddotapps.com.sg';
export const TC_API_PROD_URL = 'https://tc-prod-api.reddotapps.com.sg';
export const TC_HRMS_BASE_URL = 'https://tc-hrms.reddotapps.com.sg/all-users?type=current';
export const TC_HRMS_STAGING_URL = 'https://tc-staging-hrms.reddotapps.com.sg/all-users?type=current';
export const TC_HRMS_PROD_URL = 'https://tc-prod-hrms.reddotapps.com.sg/all-users?type=current';

axios.defaults.baseURL = 'https://tc-api.reddotapps.com.sg'
axios.defaults.headers.common['Authorization'] =
      'Bearer ' + localStorage.getItem('token')

export const THEME_CONFIG = {
	
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_DARK,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#111118',
	headerNavColor: '#111118',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
	
};
