export const BasicDetailsIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9824 15.0869C12.2598 15.0781 10.9238 13.6367 10.9238 11.7295C10.9062 9.93652 12.2686 8.44238 13.9824 8.44238C15.6963 8.44238 17.041 9.93652 17.041 11.7295C17.041 13.6367 15.7051 15.1045 13.9824 15.0869ZM13.9824 21.2393C12.0137 21.2393 9.92188 20.4219 8.59473 18.998C9.60547 17.4863 11.6094 16.6074 13.9824 16.6074C16.3291 16.6074 18.3506 17.4688 19.3701 18.998C18.043 20.4219 15.9512 21.2393 13.9824 21.2393Z" fill="#455560" />
  </svg>

);

export const BankDetailsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1904)">
      <path d="M6.5 10.0012H4.5V17.0012H6.5V10.0012ZM12.5 10.0012H10.5V17.0012H12.5V10.0012ZM21 19.0012H2V21.0012H21V19.0012ZM18.5 10.0012H16.5V17.0012H18.5V10.0012ZM11.5 3.26122L16.71 6.00122H6.29L11.5 3.26122ZM11.5 1.00122L2 6.00122V8.00122H21V6.00122L11.5 1.00122Z" fill="#455560" />
    </g>
    <defs>
      <clipPath id="clip0_467_1904">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export const AddressDetaisIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1924)">
      <path
        d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1924">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AppAccessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1916)">
      <path
        d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 21H7V20H17V21ZM17 18H7V6H17V18ZM17 4H7V3H17V4ZM16 10.05L14.59 8.64L11.05 12.18L9.64 10.77L8.23 12.18L11.05 15L16 10.05Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1916">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const EmploymentDetailsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_467_1930)">
      <path
        d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3ZM18.82 9L12 12.72L5.18 9L12 5.28L18.82 9ZM17 15.99L12 18.72L7 15.99V12.27L12 15L17 12.27V15.99Z"
        fill="#455560"
      />
    </g>
    <defs>
      <clipPath id="clip0_467_1930">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const UploadDocumentsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1910)">
      <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#455560" />
    </g>
    <defs>
      <clipPath id="clip0_467_1910">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export const AttendanceManagementIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 22q-.825 0-1.413-.587Q3 20.825 3 20V6q0-.825.587-1.412Q4.175 4 5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588Q21 5.175 21 6v14q0 .825-.587 1.413Q19.825 22 19 22Zm0-2h14V10H5v10ZM5 8h14V6H5Zm0 0V6v2Zm7 6q-.425 0-.712-.288Q11 13.425 11 13t.288-.713Q11.575 12 12 12t.713.287Q13 12.575 13 13t-.287.712Q12.425 14 12 14Zm-4 0q-.425 0-.713-.288Q7 13.425 7 13t.287-.713Q7.575 12 8 12t.713.287Q9 12.575 9 13t-.287.712Q8.425 14 8 14Zm8 0q-.425 0-.712-.288Q15 13.425 15 13t.288-.713Q15.575 12 16 12t.712.287Q17 12.575 17 13t-.288.712Q16.425 14 16 14Zm-4 4q-.425 0-.712-.288Q11 17.425 11 17t.288-.712Q11.575 16 12 16t.713.288Q13 16.575 13 17t-.287.712Q12.425 18 12 18Zm-4 0q-.425 0-.713-.288Q7 17.425 7 17t.287-.712Q7.575 16 8 16t.713.288Q9 16.575 9 17t-.287.712Q8.425 18 8 18Zm8 0q-.425 0-.712-.288Q15 17.425 15 17t.288-.712Q15.575 16 16 16t.712.288Q17 16.575 17 17t-.288.712Q16.425 18 16 18Z" fill='#455560' /></svg>
)

export const AttendanceManagementActiveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5 22q-.825 0-1.413-.587Q3 20.825 3 20V6q0-.825.587-1.412Q4.175 4 5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588Q21 5.175 21 6v14q0 .825-.587 1.413Q19.825 22 19 22Zm0-2h14V10H5v10ZM5 8h14V6H5Zm0 0V6v2Zm7 6q-.425 0-.712-.288Q11 13.425 11 13t.288-.713Q11.575 12 12 12t.713.287Q13 12.575 13 13t-.287.712Q12.425 14 12 14Zm-4 0q-.425 0-.713-.288Q7 13.425 7 13t.287-.713Q7.575 12 8 12t.713.287Q9 12.575 9 13t-.287.712Q8.425 14 8 14Zm8 0q-.425 0-.712-.288Q15 13.425 15 13t.288-.713Q15.575 12 16 12t.712.287Q17 12.575 17 13t-.288.712Q16.425 14 16 14Zm-4 4q-.425 0-.712-.288Q11 17.425 11 17t.288-.712Q11.575 16 12 16t.713.288Q13 16.575 13 17t-.287.712Q12.425 18 12 18Zm-4 0q-.425 0-.713-.288Q7 17.425 7 17t.287-.712Q7.575 16 8 16t.713.288Q9 16.575 9 17t-.287.712Q8.425 18 8 18Zm8 0q-.425 0-.712-.288Q15 17.425 15 17t.288-.712Q15.575 16 16 16t.712.288Q17 16.575 17 17t-.288.712Q16.425 18 16 18Z" fill='#F78DA7' /></svg>
)

export const AssignShiftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M11 21q-1.875 0-3.512-.7-1.638-.7-2.863-1.925T2.7 15.512Q2 13.875 2 12t.7-3.513q.7-1.637 1.925-2.862T7.488 3.7Q9.125 3 11 3q.525 0 1.012.062.488.063.988.188V5.3q-.5-.15-.988-.225Q11.525 5 11 5 8.05 5 6.025 7.025 4 9.05 4 12q0 2.95 2.025 4.975Q8.05 19 11 19q2.95 0 4.975-2.025Q18 14.95 18 12q0-.275-.025-.5-.025-.225-.075-.5h2.05q.05.275.05.5v.5q0 1.875-.7 3.512-.7 1.638-1.925 2.863T14.513 20.3Q12.875 21 11 21Zm2.8-4.8L10 12.4V7h2v4.6l3.2 3.2ZM18 9V6h-3V4h3V1h2v3h3v2h-3v3Z" /></svg>

);

export const BasicDetailsActiveIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9824 15.0869C12.2598 15.0781 10.9238 13.6367 10.9238 11.7295C10.9062 9.93652 12.2686 8.44238 13.9824 8.44238C15.6963 8.44238 17.041 9.93652 17.041 11.7295C17.041 13.6367 15.7051 15.1045 13.9824 15.0869ZM13.9824 21.2393C12.0137 21.2393 9.92188 20.4219 8.59473 18.998C9.60547 17.4863 11.6094 16.6074 13.9824 16.6074C16.3291 16.6074 18.3506 17.4688 19.3701 18.998C18.043 20.4219 15.9512 21.2393 13.9824 21.2393Z" fill="#F78DA7" />
  </svg>

);

export const BankDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1790)">
      <path d="M6.5 10.0012H4.5V17.0012H6.5V10.0012ZM12.5 10.0012H10.5V17.0012H12.5V10.0012ZM21 19.0012H2V21.0012H21V19.0012ZM18.5 10.0012H16.5V17.0012H18.5V10.0012ZM11.5 3.26122L16.71 6.00122H6.29L11.5 3.26122ZM11.5 1.00122L2 6.00122V8.00122H21V6.00122L11.5 1.00122Z" fill="#F78DA7" />
    </g>
    <defs>
      <clipPath id="clip0_467_1790">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export const AddressDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1848)">
      <path d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z" fill="#F78DA7" />
    </g>
    <defs>
      <clipPath id="clip0_467_1848">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);


export const InvoiceIcon = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V6H14V18H2ZM7 17H9V16H10C10.55 16 11 15.55 11 15V12C11 11.45 10.55 11 10 11H7V10H11V8H9V7H7V8H6C5.45 8 5 8.45 5 9V12C5 12.55 5.45 13 6 13H9V14H5V16H7V17Z" fill="#475569" />
  </svg>
)

export const InvoiceActiveIcon = () => (
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V6H14V18H2ZM7 17H9V16H10C10.55 16 11 15.55 11 15V12C11 11.45 10.55 11 10 11H7V10H11V8H9V7H7V8H6C5.45 8 5 8.45 5 9V12C5 12.55 5.45 13 6 13H9V14H5V16H7V17Z" fill="#F78DA7" />
  </svg>
)

export const UserManagementActiveIcon = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.67 9.12988C17.04 10.0599 18 11.3199 18 12.9999V15.9999H22V12.9999C22 10.8199 18.43 9.52988 15.67 9.12988Z" fill="#F78DA7" />
    <path d="M14 8C16.21 8 18 6.21 18 4C18 1.79 16.21 0 14 0C13.53 0 13.09 0.0999998 12.67 0.24C13.5 1.27 14 2.58 14 4C14 5.42 13.5 6.73 12.67 7.76C13.09 7.9 13.53 8 14 8Z" fill="#F78DA7" />
    <path d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2Z" fill="#F78DA7" />
    <path d="M8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9ZM14 14H2V13.01C2.2 12.29 5.3 11 8 11C10.7 11 13.8 12.29 14 13V14Z" fill="#F78DA7" />
  </svg>
)

export const PaymentsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M560 616q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280 736q-33 0-56.5-23.5T200 656V336q0-33 23.5-56.5T280 256h560q33 0 56.5 23.5T920 336v320q0 33-23.5 56.5T840 736H280Zm80-80h400q0-33 23.5-56.5T840 576V416q-33 0-56.5-23.5T760 336H360q0 33-23.5 56.5T280 416v160q33 0 56.5 23.5T360 656Zm440 240H120q-33 0-56.5-23.5T40 816V376h80v440h680v80ZM280 656V336v320Z" fill='#455560' /></svg>
)

export const PaymentsActiveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M560 616q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM280 736q-33 0-56.5-23.5T200 656V336q0-33 23.5-56.5T280 256h560q33 0 56.5 23.5T920 336v320q0 33-23.5 56.5T840 736H280Zm80-80h400q0-33 23.5-56.5T840 576V416q-33 0-56.5-23.5T760 336H360q0 33-23.5 56.5T280 416v160q33 0 56.5 23.5T360 656Zm440 240H120q-33 0-56.5-23.5T40 816V376h80v440h680v80ZM280 656V336v320Z" fill='#F78DA7' /></svg>
)


export const AddressDetailsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1848)">
      <path d="M12 12C10.9 12 10 11.1 10 10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 11.1 13.1 12 12 12ZM18 10.2C18 6.57 15.35 4 12 4C8.65 4 6 6.57 6 10.2C6 12.54 7.95 15.64 12 19.34C16.05 15.64 18 12.54 18 10.2ZM12 2C16.2 2 20 5.22 20 10.2C20 13.52 17.33 17.45 12 22C6.67 17.45 4 13.52 4 10.2C4 5.22 7.8 2 12 2Z" fill="#455560" />
    </g>
    <defs>
      <clipPath id="clip0_467_1848">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export const AppAccessActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1743)">
      <path d="M17 1.01L7 1C5.9 1 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V3C19 1.9 18.1 1.01 17 1.01ZM17 21H7V20H17V21ZM17 18H7V6H17V18ZM17 4H7V3H17V4ZM16 10.05L14.59 8.64L11.05 12.18L9.64 10.77L8.23 12.18L11.05 15L16 10.05Z" fill="#F78DA7" />
    </g>
    <defs>
      <clipPath id="clip0_467_1743">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export const EmploymentDetailsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1892)">
      <path d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3ZM18.82 9L12 12.72L5.18 9L12 5.28L18.82 9ZM17 15.99L12 18.72L7 15.99V12.27L12 15L17 12.27V15.99Z" fill="#F78DA7" />
    </g>
    <defs>
      <clipPath id="clip0_467_1892">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export const UploadDocumentsActiveIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_467_1758)">
      <path d="M8 16H16V18H8V16ZM8 12H16V14H8V12ZM14 2H6C4.9 2 4 2.9 4 4V20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="#F78DA7" />
    </g>
    <defs>
      <clipPath id="clip0_467_1758">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
export const AssignShiftActiveIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M11 21q-1.875 0-3.512-.7-1.638-.7-2.863-1.925T2.7 15.512Q2 13.875 2 12t.7-3.513q.7-1.637 1.925-2.862T7.488 3.7Q9.125 3 11 3q.525 0 1.012.062.488.063.988.188V5.3q-.5-.15-.988-.225Q11.525 5 11 5 8.05 5 6.025 7.025 4 9.05 4 12q0 2.95 2.025 4.975Q8.05 19 11 19q2.95 0 4.975-2.025Q18 14.95 18 12q0-.275-.025-.5-.025-.225-.075-.5h2.05q.05.275.05.5v.5q0 1.875-.7 3.512-.7 1.638-1.925 2.863T14.513 20.3Q12.875 21 11 21Zm2.8-4.8L10 12.4V7h2v4.6l3.2 3.2ZM18 9V6h-3V4h3V1h2v3h3v2h-3v3Z" fill="#F78DA7" /></svg>

);